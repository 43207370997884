<template>
  <vHeader></vHeader>
</template>

<script>
import vHeader from '@/components/template/header/Header';

export default {
  components: {
    vHeader
  }
};
</script>
